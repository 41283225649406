<template>
  <div class="container">
    <div class="row">
      <div class="col-md-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-warning">
            <i class="tim-icons icon-wifi"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Internet of things</h3>
            <p class="description">
            See how we are using active intelligence to revolutionize energy resourcefulness, create greater system awareness, and connect the Internet of Things.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="tim-icons icon-sound-wave"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Monitoring</h3>
            <p class="description">
             
           Our new technology applies to energy eliminating the lack of knowledge and generating economy and reliability.
            </p>
          </div>
        </div>
        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="tim-icons icon-tap-02"></i>
          </div>
          <div class="description">
            <h3 class="info-title">Control</h3>
            <p class="description">
              Control is mandatory in order to understand, analyze and ajust our energy behavior. So we are using the new machines to reduce energy consumption and increase the energy availability.
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-7 mr-auto">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <card class="card-register card-white">
              <template slot="header">
                <img class="card-img" src="img/card-danger.png" alt="Card image"/>
                <h4 class="card-title">  Register</h4>
              </template>
              <div class="row">
            <div class="col-md-8">
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="name"
                  placeholder="Name"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
             </ValidationProvider>
             </div>
            <div class="col-md-4">
              <ValidationProvider
                name="Phone"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="number"
                  placeholder="Phone"
                  addon-left-icon="tim-icons icon-single-02"
                  type="phone"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>

          

            <div class="col-md-12">
              <ValidationProvider
                name="Address"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="street"
                  placeholder="Address"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
          
            <!-- <div class="col-md-4">
              <ValidationProvider
                name="Neighborhood"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="neighborhood"
                  placeholder="Neighborhood"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div> -->
            <div class="col-md-4">
              <ValidationProvider
                name="city"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="city"
                  placeholder="City"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider
                name="State"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="state"
                  placeholder="State"
                  addon-left-icon="tim-icons icon-single-02"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider
                name="zip code"
                rules="required"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="zipcode"
                  placeholder="Zip Code"
                  addon-left-icon="tim-icons icon-square-pin"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
          </div>

        <div class="row">

          <div class="col-md-6">
              <ValidationProvider
                name="Complement 1"
              
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  
                  required
                  v-model="complement1"
                  placeholder="Complement 1"
                  addon-left-icon="tim-icons icon-istanbul"
                  type="text"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
             </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="Complement 2"
              
              v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                v-model="complement2"
                placeholder="Complement 2"
                addon-left-icon="tim-icons icon-istanbul"
                type="text"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
           </ValidationProvider>
        </div>
        </div>

              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="email"
                  placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85"
                  type="email"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
             </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:6"
                v-slot="{ passed, failed, errors }"
                >
                <base-input
                  required
                  v-model="password"
                  placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
             </ValidationProvider>

              <base-checkbox class="text-left">
                I agree to the <a href="https://martinsonmachine.com/policy.html" target="_blank">terms and conditions</a>.
              </base-checkbox>

              <base-button @click="registerUserAndAddToTable" native-type="submit" slot="footer" type="danger" round block size="lg">
                Get Started
              </base-button>
            </card>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>

import  supabase  from "@/supabase.js";

export default {
  data() {
    return {
      name: '',
      phone: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      zipcode: '',
      complement1: '',
      complement2: '',
      email: '',
      password: ''
    };
   
  }, 
  async created() {
      const params = new URLSearchParams(location.search)
      this.complement1=params.get("complement1")
  },
  methods: {
    async registerUserAndAddToTable() { 
      
      const { data, error } = await supabase.auth.signUp({
        email: this.email,
        password: this.password,
        phone:this.phone,
      });
 
    console.log("teste");
      if (error) {
        console.error(error);
        return;
      }

      console.log("Usuário registrado:", data);
      const { data: existingUsers, error: selectError } = await supabase
      .from("customers")
      .select("*")
      .eq("email", this.email);

      if (selectError) {
        console.error(selectError);
        return;
      }

      if (existingUsers.length > 0) {
        alert("user already registred")
        return;
      }
      await this.addUserToTable(data.user.id);

    },
    async addUserToTable(id) {
   
      const { data, error } = await supabase
        .from("customers")
        .insert([
          {
            id:id,
            name: this.name,
            phone: this.phone,
            address: this.street,
            phone: this.number,
            // neighborhood: this.neighborhood,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            complement1: this.complement1,
            complement2: this.complement2,
            email: this.email,
          },
        ]);
        console.log(data)
        console.log(error)
        if (error) {
          console.error(error);
          return;
        }

      console.log("Usuário adicionado à tabela Customers:", data);

      this.$router.push('/')


    },
  },
};
</script>
<style></style>